import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Colorwash from '../components/Colorwash';
import FooterLogin from '../components/footer/FooterLoginBlock';
import HeaderEmpty from '../components/header/HeaderEmpty';
import HeaderTags from '../components/header/HeaderTags';
import { redirectUserToLoginPage } from '../helpers/user';
import ThemeContext from '../providers/ThemeProvider';

const LoginLayout = ({ children, title }) => {
  const theme = useContext(ThemeContext);
  const Environment = process.env.NEXT_PUBLIC_SITE || 'wowcher';
  const userState = useSelector((state) => state.user.isAuthenticated);
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    redirectUserToLoginPage(userState, router, dispatch);
  }, [userState, router, dispatch]);

  return (
    <>
      <HeaderTags title={title} />
      <Colorwash />
      <HeaderEmpty site={Environment} />
      <div className="app app--full-height" id="main-view">
        <div className="content">
          <div className="inner">{children}</div>
        </div>
      </div>
      <FooterLogin />

      <style jsx>{`
        .content {
          width: 100%;
          max-width: 1024px;
          margin: 0 auto;
          padding: 30px 0;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .inner {
            padding-left: 12%;
            padding-right: 12%;
          }
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .content {
            padding-top: 0;
          }
        }
      `}</style>

      <style global jsx>{`
        html {
          font-size: 10px;
        }
      `}</style>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

LoginLayout.defaultProps = {
  children: null,
  title: '',
};

export default LoginLayout;
