import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MINI_MENU, RAF_ENABLED_MINI_MENU } from '../../config/text/text';
import { isWowcherBrand } from '../../helpers/pages';
import { redirectToURL } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import SelectMenu from '../_generic/select-menu/SelectMenu';

const MyAccountMobileNavigation = () => {
  const theme = useContext(ThemeContext);
  const router = useRouter();

  // RAF Enabled mobile menu controlled by the env variable
  const rafEnabled = JSON.parse(process.env.NEXT_PUBLIC_RAF_ENABLED === 'true');
  const isWowcher = isWowcherBrand();
  const isVipUser = useSelector((state) => state.user.userprofile.vipUser);

  let options =
    rafEnabled && isWowcher
      ? RAF_ENABLED_MINI_MENU[process.env.NEXT_PUBLIC_SITE]
      : MINI_MENU[process.env.NEXT_PUBLIC_SITE];

  if (!isVipUser) {
    options = options.filter((option) => !option.isVipOnly);
  }

  return (
    <>
      <div className="select">
        <SelectMenu
          handleChange={(item) => redirectToURL(item.value, router)}
          options={options}
          selected={router.pathname}
        />
      </div>
      <style jsx>{`
        .select {
          display: block;
          width: 177px;
        }

        @media (min-width: ${theme.breakpoints.mdUp}) {
          .select {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default MyAccountMobileNavigation;
